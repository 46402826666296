import {
  TParadiseHomesListFilter,
  validParadiseHomesSortIds,
} from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { arrayIncludes } from "src/utils/genericUtil"

export function parseParadiseHomeSortField(
  sortValue: string | undefined
): sortValue is TParadiseHomesListFilter["sort"] {
  if (!sortValue || !arrayIncludes(validParadiseHomesSortIds, sortValue)) {
    return false
  }

  return true
}
