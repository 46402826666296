export const validParadiseHomesSortIds = [
  "name",
  "createdAt",
  "deletedAt",
] as const

export type TParadiseHomesListFilter = {
  offset?: number
  limit?: number
  name?: string
  tag?: string
  user_id?: string
  home_id?: string
  deleted?: boolean
  sort?: (typeof validParadiseHomesSortIds)[number]
  sort_by?: "desc" | "asc"
}

export interface IParadiseHome {
  owner: {
    id: string
    type: string
  }
  location: {
    longitude: number
    latitude: number
  }
  alarm_info: {
    events: []
  }
  disturbance_monitoring: {
    noise_count: number
    current_state_update_at: string
    local_reaction: boolean
    state: string
  }
  guest_communication: {
    checkin_message: string
  }
  smoking_detection: {
    available: boolean
    state: string
    updated_at: string
  }
  alarm_status: string
  alarm_mode: string
  silent_alarm: boolean
  scheduled_alarm_active: boolean
  tags: []
  disturbance_monitoring_enabled: boolean
  occupancy: {
    active: boolean
    capacity: number
    latest_scanned_devices: number
    status: string
    id: string
  }
  id: string
  org_id: string
  created_by: string
  name: string
  timezone: string
  invitations: []
  members: [
    {
      status: string
      google_linked: boolean
      alarm_reminder_notification: string
      alarm_off_reminder_notification: string
      feature_access: []
      id: string
      joined_at: string
      scopes: [string]
      member_id: string
      user_id: string
    },
  ]
  friends: []
  deleted: boolean
  deleted_at?: string
  created_at: string
  v: number
  violations: [string]
  profile_id: string
  response_service: {
    active: boolean
    available: boolean
    configured: boolean
    id: string
  }
  home_access_group_id: string
  organization_access_group_id: string
  disturbance_monitoring_active: boolean
  updated_at: string
  home_id: string
  devices: []
}

export interface IParadiseHomesResponse {
  total_count: number
  offset: number
  limit: number
  homes: IParadiseHome[]
}

export interface IParadiseHomeLogFilter {
  offset?: number
  limit?: number
}

export interface IParadiseHomeLog {
  created_at: string
  home_id: string
  home_event: string
  alarm_mode?: string
  user_id?: string
}

export interface IParadiseHomeLogResponse {
  entries: IParadiseHomeLog[]
}

export interface IParadiseTransferHomeToNewOrgBody {
  new_org_id: string
  homes: string[]
}

export interface IParadiseUpdateHomeTagsBody {
  tags: string[]
}

export interface IParadiseUpdateHomeTagsResponse {
  home_id: string
  tags: string[]
}

export type TParadisePostCsvHomesResponse = {
  homes_imported: number
}

export type TParadisePostCsvHomesError =
  | {
      errors: {
        row: number
        error: string
        error_key: string
      }[]
    }
  | {
      message: string
      error: string
      error_key: string
    }
